<template>
  <!-- 5月11日完成  开发人员：王世龙 -->
  <!-- 头部组件 -->
  <div>
    <main>
      <!-- 背景1 -->
      <div class="bg1">
        <!-- <Header></Header> -->
        <!-- 内容 -->
        <div class="father">
          <div class="son1">
            <h2 class="yhwh">运河企业智慧生态</h2>
            <p class="gh">为企业赋能、为梦想起航 </p>
            <el-button type="text" class="open" @click="open"> </el-button>
          </div>
        </div>
        <video class="bg1_mp4" muted loop autoplay>
          <source v-if="shows" src="./imgs/bg1.mp4" type="video/mp4" />
        </video>
        <video
          class="bg_video"
          autoplay
          loop
          preload="auto"
          v-if="videoz"
          controls
        >
          <source src="./imgs/bg_video.mp4" type="video/mp4" />
        </video>

        <div class="image_icon" @click="refresh" v-if="is_show"></div>
      </div>
      <!-- 背景1 -->
      <!-- 背景2 -->
      <div class="bg2">
        <div class="son" key="index">
          <div class="son2">
            <span class="gy">关于</span>
            <span class="whgy">大运河国家文化公园 <br />数字云平台</span>
            <!-- <el-button round class="ljgd">了解更多</el-button> -->
            <span class="hdz"
              >2019年12月，大运河国家文化公园数字云平台建设列入中办、国办的《长城、大运河、长征国家文化公园建设方案》。江苏省委省政府高度重视，江苏省委十三届七次、八次全会上均强调，深入推进大运河国家文化公园数字云平台等重大标志性项目的建设，构建集管理监测、文化研究、展示传播、学习教育、休闲娱乐等一体化服务平台。</span
            >
          </div>
        </div>
      </div>
      <!-- 背景2 -->
      <!-- 背景3 -->
      <div class="bg3">
        <span class="dyhstl">大运河生态链</span>
        <span class="wlct">打造文旅发展新引擎、文旅产业新样板</span>

        <img src="./imgs/cloud.webp" alt="" class="clouds" v-show="cloud" />
        <img src="./imgs/cloud2.webp" alt="" class="clouds2" v-show="clouda" />
        <img src="./imgs/cloud3.webp" alt="" class="clouds3" v-show="cloudb" />
        <img src="./imgs/cloud4.png" alt="" class="clouds4" v-show="cloudc" />
        <img src="./imgs/cloud5.png" alt="" class="clouds5" v-show="cloudd" />
        <img src="./imgs/cloud6.png" alt="" class="clouds6" v-show="cloude" />

        <el-button type="text" class="zhjq" @click="over1">
          <span class="zhjq1">智慧景区</span>
        </el-button>
        <el-button type="text" class="zhwb" @click="over2">
          <span class="zhbwg1"> 智慧博物馆</span></el-button
        >
        <el-button type="text" class="zhwc" @click="over3">
          <span class="zhwc1">智慧文创</span>
        </el-button>
        <el-button type="text" class="zhjd" @click="over4">
          <span class="zhjd1"> 智慧酒店</span></el-button
        >
        <el-button type="text" class="zhgjx" @click="over5">
          <span class="zhgjx1"> 智慧工具箱</span></el-button
        >
        <el-button type="text" class="zhbg" @click="over6">
          <span class="zhbg1"> 智慧办公</span></el-button
        >
      </div>
      <!-- 背景3 -->

      <!-- 背景4 -->
      <div class="bg4">
        <span class="hzkh">合作伙伴</span>
        <img src="./imgs/hw.jpeg" alt="" class="hw" />
        <img src="./imgs/fddx.jpeg" alt="" class="fddx" />
        <!-- <img src="./imgs/fhsjkj.png" alt="" class="fh" /> -->
        <img src="./imgs/zjtd.jpeg" alt="" class="zj" />
        <img src="./imgs/st.png" alt="" class="st" />
        <img src="./imgs/xmly.jpeg" alt="" class="xmly" />
        <img src="./imgs/blbl.webp" alt="" class="blbl" />
        <img src="./imgs/icon3.png" alt="" class="icon3" />
        <img src="./imgs/icon4.png" alt="" class="icon4" />
        <!-- <img src="./imgs/icon5.png" alt="" class="icon5" /> -->
        <!-- <img src="./imgs/icon6.png" alt="" class="icon6" /> -->
        <!-- <img src="./imgs/icon7.png" alt="" class="icon7" /> -->
        <img src="./imgs/icon8.png" alt="" class="icon8" />
        <!-- <img src="./imgs/icon9.png" alt="" class="icon9" /> -->
        <img src="./imgs/icon10.png" alt="" class="icon10" />
        <img src="./imgs/icon11.png" alt="" class="icon11" />
        <img src="./imgs/icon12.png" alt="" class="icon12" />
        <img src="./imgs/icon13.png" alt="" class="icon13" />
        <img src="./imgs/icon14.png" alt="" class="icon14" />
        <img src="./imgs/icon15.png" alt="" class="icon15" />
        <img src="./imgs/icon16.png" alt="" class="icon16" />
        <img src="./imgs/icon17.png" alt="" class="icon17" />
        <img src="./imgs/icon18.png" alt="" class="icon18" />
        <img src="./imgs/icon19.png" alt="" class="icon19" />
        <!-- <img src="./imgs/icon20.png" alt="" class="icon20" /> -->
        <img src="./imgs/new1.png" alt="" class="new1" />
        <img src="./imgs/new2.png" alt="" class="new2" />
        <img src="./imgs/new3.png" alt="" class="new3" />
        <!-- <img src="./imgs/new4.png" alt="" class="new4" /> -->
        <!-- <img src="./imgs/new5.png" alt="" class="new5" /> -->
      </div>
      <!-- 背景4 -->
    </main>
    <!-- 底部组件 -->
    <Header></Header>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an ael-buttonas to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
import $ from "jquery";
$(function () {});
export default {
  async mounted() {
    window.onscroll = function () {
      var scrollTop = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop < 1000) {
        // console.log("ok");
        document.querySelector(".header").style.backgroundColor = "";
        document.querySelector(".son").style.display = "none";
      } else {
        // console.log(666666);
        document.querySelector(".header").style.backgroundColor = "black";
        document.querySelector(".son").style.display = "block";
      }

      console.log(scrollTop);
      // if (scrollTop > 1000) {
      //   console.log("ok");
      //   // document.querySelector('.son2').style.animation = 'sport .5s'
      //   // document.querySelector('.son2').style.transform = 'translateY(0px)'
      //   document.querySelector(".son").style.display = "block";
      // } else {
      //   // document.querySelector('.son2').style.animation = ''
      //   // document.querySelector('.son2').style.transform = ''
      //   document.querySelector(".son").style.display = "none";
      // }
    };
  },

  // name: "home",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      cloud: true,
      clouda: false,
      cloudb: false,
      cloudc: false,
      cloudd: false,
      cloude: false,
      video: false,
      is_show: false,
      videoz: false,
      shows: true,
    };
  },
  methods: {
    over1() {
      this.cloud = true;
      this.clouda = false;
      this.cloudb = false;
      this.cloudc = false;
      this.cloudd = false;
      this.cloude = false;
    },
    over2() {
      this.cloud = false;
      this.clouda = true;
      this.cloudb = false;
      this.cloudc = false;
      this.cloudd = false;
      this.cloude = false;
    },
    over3() {
      this.cloud = false;
      this.clouda = false;
      this.cloudb = true;
      this.cloudc = false;
      this.cloudd = false;
      this.cloude = false;
    },
    over4() {
      this.cloud = false;
      this.clouda = false;
      this.cloudb = false;
      this.cloudc = true;
      this.cloudd = false;
      this.cloude = false;
    },
    over5() {
      this.cloud = false;
      this.clouda = false;
      this.cloudb = false;
      this.cloudc = false;
      this.cloudd = true;
      this.cloude = false;
    },
    over6() {
      this.cloud = false;
      this.clouda = false;
      this.cloudb = false;
      this.cloudc = false;
      this.cloudd = false;
      this.cloude = true;
    },
    open() {
      this.video = true;
      this.videoz = true;
      this.is_show = true;
      this.shows = false;
    },
    // move() {
    //   this.cloud = false;
    // },
    refresh() {
      // window.location.reload();
      this.videoz = false;
      this.is_show = false;
      this.shows = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.son2 {
  animation: sport 1s;
  transform: translateY(0px);
  min-width: 300px;
  height: 80px;
}
.son1 {
  animation: sport 1s;
  transform: translateY(0px);
  min-width: 300px;
  height: 80px;
}
.bg1 {
  margin: 0 !important;
  padding: 0 !important;
  height: 1080px;
  width: 100%;
  overflow: hidden;
  position: relative;
  // background-image: url(./imgs/bg1.gif);
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  .bg1_mp4 {
    display: block;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .open {
    min-width: 100px;
    height: 100px;
    background-image: url(./imgs/open.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 561px;
    left: 361px;
    border-radius: 50%;
  }
  .bg_video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
  }
  .gh {
    width: 639.9936px;
    height: 33px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 22.729vw;
    left: 19vw;
    text-align: left;
  }
  .lycb {
    min-width: 412px;
    height: 67px;
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 3px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 403.008px;
    left: 360.9984px;
  }
  .yhwh {
    min-width: 412px;
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 3px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 320.0064px;
    left: 360.9984px;
  }
}
.bg2 {
  height: 55.25vw;
  background-image: url(./imgs/bg1.webp);
  background-repeat: no-repeat;
  position: relative;
  background-size: 100%;
  .gy {
    min-width: 80px;
    height: 40px;
    font-size: 40px;
    color: #ffffff;
    position: absolute;
    top: 700px;
    left: 360px;
  }
  .whgy {
    min-width: 350px;
    height: 90px;
    font-size: 32px;
    color: #ffffff;
    position: absolute;
    top: 800px;
    left: 360px;
    text-align: left;
  }
  .hdz {
    width: 650px;
    height: 180px;
    font-size: 20.0064px;
    color: #ffffff;
    position: absolute;
    top: 790.0032px;
    left: 863.0016px;
    text-align: left;
  }
  .ljgd {
    position: absolute;
    top: 931.008px;
    left: 360px;
    color: black;
  }
}
.bg3 {
  height: 1080px;
  background-image: url(./imgs/bg4.webp);
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .dyhstl {
    min-width: 248px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 80.0064px;
    left: 836.0064px;
  }
  .wlct {
    min-width: 357px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 152.0064px;
    left: 781.9968px;
  }

  .clouds {
    position: absolute;
    top: 412.992px;
    left: 594px;
    width: 732px;
    height: 128px;
  }
  .clouds2 {
    position: absolute;
    top: 412.992px;
    left: 594px;
    width: 732px;
    height: 128px;
  }
  .clouds3 {
    position: absolute;
    top: 412.992px;
    left: 594px;
    width: 712px;
    height: 128px;
  }
  .clouds4 {
    position: absolute;
    top: 412.992px;
    left: 594px;
    width: 732px;
    height: 128px;
  }
  .clouds5 {
    position: absolute;
    top: 412.992px;
    left: 594px;
    width: 732px;
    height: 128px;
  }
  .clouds6 {
    position: absolute;
    top: 412.992px;
    left: 700px;
    width: 533px;
    height: 128px;
  }
  .zhjq {
    height: 76px;
    min-width: 70px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 653px;
    left: 566px;
    display: flex;
    align-items: flex-start;
    background-image: url(./imgs/ship1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0vw 2vw;
    .zhjq1 {
      position: absolute;
      top: 20px;
    }
  }

  .zhjq:hover {
    background-image: url(./imgs/colorship3.png);
    height: 100px;
    min-width: 100px;
    background-size: 100%;
    color: #856959;
    background-position: -0.5vw 2vw;
    transition: 1s;
  }
  /deep/.zhjq:focus {
    background-image: url(./imgs/colorship1.png);
    color: #856959;
    outline: none;
  }
  /deep/.zhwb {
    height: 127px;
    min-width: 108px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 570px;
    left: 654px;
    display: flex;
    align-items: flex-start;
    background-image: url(./imgs/ship2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -0.5vw 2vw;
    .zhbwg1 {
      position: absolute;
      top: 20px;
      left: 0;
    }
  }
  /deep/.zhwb:focus {
    background-image: url(./imgs/colorship2.png);
    color: #856959;
    outline: none;
  }
  .zhwb:hover {
    background-image: url(./imgs/colorship4.png);
    height: 140px;
    min-width: 130px;
    background-size: 100%;
    color: #856959;
    background-position: -1vw 2vw;
    transition: 1s;
  }
  .zhwc {
    vertical-align: text-top;
    height: 127px;
    min-width: 108px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 600px;
    left: 800px;
    display: flex;
    align-items: flex-start;
    background-image: url(./imgs/ship2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -0.7vw 2vw;
    .zhwc1 {
      position: absolute;
      top: 20px;
      left: 3px;
    }
  }
  /deep/.zhwc:focus {
    background-image: url(./imgs/colorship2.png);
    color: #856959;
    outline: none;
  }
  .zhwc:hover {
    background-image: url(./imgs/colorship4.png);
    height: 120px;
    min-width: 130px;
    background-size: 100%;
    color: #856959;
    background-position: -1vw 2vw;
    transition: 1s;
  }
  .zhjd {
    height: 76px;
    min-width: 70px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 627px;
    left: 950px;
    display: flex;
    align-items: flex-start;
    background-image: url(./imgs/ship1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0vw 2vw;
    .zhjd1 {
      position: absolute;
      top: 20px;
    }
  }
  /deep/.zhjd:focus {
    background-image: url(./imgs/colorship1.png);
    color: #856959;
    outline: none;
  }
  .zhjd:hover {
    background-image: url(./imgs/colorship3.png);
    height: 100px;
    min-width: 100px;
    background-size: 100%;
    color: #856959;
    background-position: -0.5vw 2vw;
    transition: 1s;
  }
  .zhgjx {
    height: 76px;
    min-width: 90px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 581px;
    left: 1080px;
    // display: flex;
    // align-items: flex-start;
    background-image: url(./imgs/ship1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -0.2vw 2vw;
    .zhgjx1 {
      position: absolute;
      top: 20px;
      left: 0;
    }
  }
  /deep/.zhgjx:focus {
    background-image: url(./imgs/colorship1.png);
    color: #856959;
    outline: none;
  }
  .zhgjx:hover {
    background-image: url(./imgs/colorship3.png);
    height: 100px;
    min-width: 100px;
    background-size: 100%;
    color: #856959;
    background-position: -0.3vw 2vw;
    transition: 1s;
  }
  .zhbg {
    height: 127px;
    min-width: 108px;
    font-size: 16px;
    font-weight: 900;
    color: #333333;
    position: absolute;
    top: 620px;
    left: 1240px;
    display: flex;
    align-items: flex-start;
    background-image: url(./imgs/ship2.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: -0.3vw 2vw;
    .zhbg1 {
      position: absolute;
      top: 20px;
      left: 10px;
    }
  }
  /deep/.zhbg:focus {
    background-image: url(./imgs/colorship2.png);
    color: #856959;
    outline: none;
  }
  .zhbg:hover {
    background-image: url(./imgs/colorship4.png);
    height: 100px;
    min-width: 130px;
    background-size: 100%;
    color: #856959;
    background-position: -0.9vw 2vw;
    transition: 1s;
  }
}

.bg4 {
  background-color: #fff;
  height: 57.708vw;
  position: relative;
  .hzkh {
    width: 167px;
    height: 56px;
    font-size: 40px;
    font-weight: 400;
    color: #333333;
    position: absolute;
    top: 117px;
    left: 876px;
  }
  .new1 {
    width: 8.573vw;
    height: 6.083vw;
    position: absolute;
    top: 20.115vw;
    left: 20.833vw;
  }
  .new2 {
    width: 10.573vw;
    height: 5.083vw;
    position: absolute;
    top: 21.115vw;
    left: 32.833vw;
  }
  .new3 {
    width: 11.573vw;
    height: 6.083vw;
    position: absolute;
    top: 20.115vw;
    left: 44.833vw;
  }
  .new4 {
    width: 11.573vw;
    height: 6.083vw;
    position: absolute;
    top: 20.115vw;
    left: 55.833vw;
  }
  .new5 {
    width: 10.573vw;
    height: 4.083vw;
    position: absolute;
    top: 21.115vw;
    left: 70vw;
  }
  // 第一排
  .hw {
    width: 6.573vw;
    height: 5.083vw;
    position: absolute;
    top: 12.115vw;
    left: 59vw;
  }
  .fddx {
    width: 7.573vw;
    height: 6.083vw;
    position: absolute;
    top: 29.031vw;
    left: 33.604vw;
  }
  // .fh {
  //   width: 203px;
  //   height: 40px;
  //   position: absolute;
  //   top: 574px;
  //   left: 628px;
  // }
  .zj {
    width: 9.208vw;
    height: 9.417vw;
    position: absolute;
    top: 18.9vw;
    left: 70.573vw;
  }
  .st {
    width: 8.823vw;
    height: 3.125vw;
    position: absolute;
    top: 46.542vw;
    left: 20.563vw;
  }
  .xmly {
    width: 7.208vw;
    height: 5.417vw;
    position: absolute;
    top: 29.115vw;
    left: 71.833vw;
  }
  .blbl {
    width: 11.208vw;
    height: 6.417vw;
    position: absolute;
    top: 28.115vw;
    left: 57vw;
  }
  // 第一排
  .icon3 {
    width: 10.573vw;
    height: 2.083vw;
    position: absolute;
    top: 14.115vw;
    left: 20.833vw;
  }

  .icon4 {
    width: 8.229vw;
    height: 2.188vw;
    position: absolute;
    top: 46.698vw;
    left: 33.708vw;
  }
  .icon5 {
    width: 192px;
    height: 37px;
    position: absolute;
    top: 31.24vw;
    left: 45.26vw;
  }
  .icon6 {
    width: 9.479vw;
    height: 1.875vw;
    position: absolute;
    top: 54.8vw;
    left: 20.833vw;
  }
  .icon7 {
    width: 8.333vw;
    height: 3.125vw;
    position: absolute;
    top: 30.615vw;
    left: 58.333vw;
  }
  // 第一排
  .icon8 {
    width: 171px;
    height: 44px;
    position: absolute;
    top: 14.115vw;
    left: 34vw;
  }
  // .icon9 {
  //   width: 153px;
  //   height: 98px;
  //   position: absolute;
  //   top: 556px;
  //   left: 403px;
  // }
  // 第一排
  .icon10 {
    width: 9.583vw;
    height: 3.229vw;
    position: absolute;
    top: 13.115vw;
    left: 70.125vw;
  }
  .icon11 {
    width: 140px;
    height: 46px;
    position: absolute;
    top: 38.5vw;
    left: 403px;
  }
  .icon12 {
    width: 9.375vw;
    height: 4.375vw;
    position: absolute;
    top: 37.896vw;
    left: 32.708vw;
  }
  .icon13 {
    width: 10.313vw;
    height: 2.917vw;
    position: absolute;
    top: 38.5vw;
    left: 45.4vw;
  }
  .icon14 {
    width: 9.167vw;
    height: 2.396vw;
    position: absolute;
    top: 39.01vw;
    left: 58.417vw;
  }
  .icon15 {
    width: 10vw;
    height: 3.333vw;
    position: absolute;
    top: 38.49vw;
    left: 70.5vw;
  }
  // 第一排
  .icon16 {
    width: 131px;
    height: 60px;
    position: absolute;
    top: 13.3vw;
    left: 47vw;
  }
  .icon17 {
    width: 9.583vw;
    height: 2.917vw;
    position: absolute;
    top: 22.083vw;
    left: 57.885vw;
  }
  .icon18 {
    width: 5.125vw;
    height: 5.073vw;
    position: absolute;
    top: 29.594vw;
    left: 47.438vw;
  }
  .icon20 {
    width: 6.646vw;
    height: 6.646vw;
    position: absolute;
    top: 52.594vw;
    left: 34.333vw;
  }
  .icon19 {
    width: 10.646vw;
    height: 6.646vw;
    position: absolute;
    top: 29vw;
    left: 20vw;
  }
}
// 新增
.image_icon {
  position: absolute;
  top: 90px;
  right: 10px;
  background-image: url(./imgs/close.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
